html {
  background: radial-gradient(circle at center, #636363, black 100%);
}

html, body, button, input {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', sans-serif !important;
}

html, body, video, #root, #content, #adContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

@keyframes wobble {
  0% {
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  90% {
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  95% {
    transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
}

#playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  border: none;
  outline: none;
  background: transparent;
  appearance: none;
  cursor: pointer;
  opacity: 0.5;
  animation: wobble 5s ease-out infinite;
}

#playButton.playing {
  display: none;
}