body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background: -webkit-radial-gradient(center, circle, #636363, black 100%);
  background: radial-gradient(circle at center, #636363, black 100%);
}

html, body, button, input {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', sans-serif !important;
}

html, body, video, #root, #content, #adContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  90% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  95% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
            transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  90% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
  95% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
            transform: translate3d(-50%, -50%, 0) scale3d(1.2, 1.2, 1.2);
  }
  100% {
    -webkit-transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
            transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
  }
}

#playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);

  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  opacity: 0.5;
  -webkit-animation: wobble 5s ease-out infinite;
          animation: wobble 5s ease-out infinite;
}

#playButton.playing {
  display: none;
}
